import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { MyImage } from '@/components/common/MyImage';

import Footer from '../components/common/Footer';
import Spacer from '../components/layout/Spacer';
import CareerHeader from '../components/pages/Career/CareerHeader';

const CareerPage = () => {
  return (
    <Wrapper
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0, y: 50 }}
    >
      <CareerHeader bg_img={<MyImage src="/career/career_top.jpg" />}>
        <h1>Career</h1>
        <div className="line"></div>
        <Spacer height="min(46px, 3.194vw)" />
        <p>UnderConstruction</p>
      </CareerHeader>

      <Spacer height="min(5vw, 150px)" />

      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  .career-body {
    width: 80%;
    margin: 0 auto;
  }
`;

export default CareerPage;
